import ppp from "../images/perfectpetpicker.png";
import mark from "../images/marking.png";
import encryption from "../images/encryption.png";
import airport from "../images/airport.png";
import garage from "../images/garage.png";
import pizza from "../images/pizza.png";
import wishlist from "../images/wishlist.png";

export const projects = {
  projects: [
    {
      title: "Perfect Pet Picker",
      img: ppp,
      skills:
        "HTML | CSS | Bootstrap | JavaScript | Python Flask | JQuery | Ajax | MySQL | Git",
      desc: "This project was developed as my university final year project, designed to help prospective pet owners find the right pet for them. This is accomplished through a quiz that asks the user questions then shows them a list of suitable pets; a forum where users can interact; a map where users can find important locations near them (e.g. vets, pet shops etc.) and a recommendation system using a memory-based user-based collaborative filtering model. The recommendation system was really interesting to design as it uses the favourites of similar users to recommend possible favourites to the current user - not just recommending similar items but using user history.",
      link: "https://perfect-pet-picker.herokuapp.com/pythonlogin/home",
    },
    {
      title: "Student Marking System",
      img: mark,
      skills: "Python",
      desc: "This Python project asks the user - a teacher - to enter the grades of their students and then enter a negative number once they are finished. These values will then be displayed to the user in a graph, as well as providing important statistics including average, lowest score etc. While the application is not particularly complex, it was one of my first Python projects so was a great way to test my skills.",
      link: "https://www.online-python.com/NR1gyzpIO4",
    },
    {
      title: "Pizza Ordering Application",
      img: pizza,
      skills: "PHP | Codelgniter | Bootstrap | Git",
      desc: "A replica of many pizza ordering systems, this application was developed using PHP and Codelgniter framework. The system works by showing the user a pizza menu with pizza, sides and deals. The user can choose a small, medium or large pizza with a range of extra toppings and deals can be selected with a choice of drinks. Once selected, menu options are added to the basket where the user can change, remove or multiply their items. When the user is happy, they can order their basket and will be asked to enter their details. Once these are confirmed the user will be provided a time in 30 minutes when their order will arrive. Throughout the development of this project I focused on user experience by providing confirmation messages at every stage.",
      link: "https://pizza-ordering-application.herokuapp.com/",
    },
    {
      title: "Garage Booking System",
      img: garage,
      skills: "Java | JFrame",
      desc: "Using Java, this project is designed to act as a garage system - showing the vehicles registered to a garage and then allowing them to be booked in to the garage itself. The system allows users to add new vehicles, delete vehicles, print the list of current vehicles, save the stock list to a file and access a GUI. The system differentiates between cars and motorbikes, asking for different information depending on which is chosen. When the GUI is opened, a table is shown with each registered vehicle. The user can filter the vehicles depending on their type and book a chosen vehicle in to a service. To do this the user must select the vehicle, choose a pick-up date, a drop-off date then book. This was an interesting project as I had never used JFrames before so it was a great new way to display Java code.",
    },
    {
      title: "Wishlist Application",
      img: wishlist,
      skills: "PHP | Ajax | JQuery | Codelgniter | Bootstrap | Git",
      desc: "This application was developed to practice my Ajax skills by creating a list system. First the user is asked to login or register if they don't have a login yet. They are then taken to their list page, where if the user already has a list it is displayed but if not they are asked to name and describe their list. Users can then add items, giving them a title, priority, price and URL. Items in the list can be searched, filtered, multiplied, removed and edited. Logged-in users can then share their list with others using a link provided.",
      link: "https://wishlist-codelgniter.herokuapp.com/",
    },
    {
      title: "Encryption System",
      img: encryption,
      skills: "Python",
      desc: "This encryption system was used as practice for creating a simple shift cipher encryption program. It allows the user to encrypt and decrypt a string, as well as decrypting a string using a plain text word that is in the string itself. To encrypt, the user chooses a key (a number) and all characters are shifted based on this key - with the opposite occuring for a decryption. For the extended decryption method, the program goes through every character of the plain text word and encrypts them using a range of keys. Then if it finds a key that makes the plain text match the encryption, it uses this key to decrypt the rest of the message.",
      link: "https://www.online-python.com/rWgIyZj29v",
    },
    {
      title: "Airport Queue Simulation",
      img: airport,
      skills: "Java",
      desc: "This airport system helped develop my understanding of queues - pushing and popping passengers depending on where they are in the order. Users can add a passenger to the queue, remove passengers, display the entire queue, store the queue data in a file, load a previous queue from a file or run a simulation. The simulation is designed to display the full abilities of the queue: loading a collection of randomly generated passengers and boarding them on to the flight, removing them appropriately from the queue as it goes. This was a good exercise to practice making my own objects, as well as queue principles.",
    },
  ],
};
