import React from "react";
import { Fade } from "react-reveal";

const Project = ({ project }) => {
  return (
    <Fade bottom>
      <td>
        <a href={project.link}>
          <img className="projectImage" src={project.img} alt={project.img} />
        </a>
      </td>
      <td>
        <b>{project.title}</b>
        <br />
        <i>{project.skills}</i>
        <p>{project.desc}</p>
        {project.link && (
          <p>
            To view the web application click <a href={project.link}>here</a>
          </p>
        )}
      </td>
    </Fade>
  );
};

export default Project;
