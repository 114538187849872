import React from "react";

const Intro = () => {
  return (
    <p className="headerText">
      I am a software engineer, with a focus on web development and user
      experience. With a first in Software Engineering from the University of
      Westminster, I have a range of skills such as web design, software
      development and general information technology. A full list of my
      technological skills are displayed below. <br />
      <br />
      Professionally, I aim to practically apply myself to coding, as well as
      engage with other developers to produce outstanding code and applications.
      An important part of the production process is expressing views and
      explaining the application to stakeholders and other professionals; a role
      which I am extremely competent in. Many years of working in customer
      service and a range of project demonstrations (for example, my Expended
      Project Qualification on Jack the Ripper which achieved an A*) have
      developed my leadership and presentation abilities. <br />
      <br />
      Computing has been a passion of mine since I was a young girl, from
      helping teachers fix computing issues in primary school to pursuing a
      range of practical work experience throughout secondary school and beyond.
      I have completed a range of engaging and enriching projects -
      educationally, professionally and personally. A sample of said projects
      have been described on this page.
      <br />
      <br />
      All of these factors come together to make my the well-rounded
      professional I am today and my hobbies such as crocheting, gardening and
      reading further enrich my capabilities as an individual.
    </p>
  );
};

export default Intro;
