import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { skills } from "../data/skills";

const Skills = () => {
  return (
    <table className="skillsTable">
      <thead>
        <tr>
          <th className="skillRow">Skill</th>
          <th>Level</th>
        </tr>
      </thead>
      <tbody>
        {skills.skills.map((skill) => {
          return (
            <tr>
              <td>{skill.skill}</td>
              <td>
                <ProgressBar
                  variant="info"
                  className="progressBar"
                  now={skill.level}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Skills;
