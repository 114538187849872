import React, { Component } from "react";
import "./App.css";
import ScrollButton from "./ScrollButton";

import Header from "./components/Header";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Footer from "./components/Footer";
import "bootstrap/dist/css/bootstrap.min.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <Header />
        <Skills />
        <Projects />
        <Footer />
        <ScrollButton />
      </div>
    );
  }
}

export default App;
