import React from "react";

const FooterLink = (props) => {
  return (
    <a href={props.link} target="_blank" rel="noreferrer">
      <img className="icons" src={props.source} alt={props.alt}></img>
    </a>
  );
};
export default FooterLink;
