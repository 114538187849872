import React from "react";
import mail from "../images/envelope-square-solid.svg";
import facebook from "../images/facebook-square-brands.svg";
import instagram from "../images/instagram-square-brands.svg";
import linkedin from "../images/linkedin-brands.svg";
import pinterest from "../images/pinterest-square-brands.svg";
import cv from "../cv.pdf";
import FooterLink from "./FooterLink";

const Footer = () => {
  return (
    <footer>
      <div className="links">
        <a href="mailto: bethany.hodd@gmail.com">
          <img className="icons" src={mail} alt="email address"></img>
        </a>
        <FooterLink
          link="https://www.facebook.com/bethany.hodd/"
          source={facebook}
          alt="facebook"
        />
        <FooterLink
          link="https://www.instagram.com/bethany_hodd/"
          source={instagram}
          alt="instagram"
        />
        <FooterLink
          link="https://www.linkedin.com/in/bethany-hodd-8556a7184/"
          source={linkedin}
          alt="linkedin"
        />
        <FooterLink
          link="https://www.pinterest.co.uk/bethanyhodd/"
          source={pinterest}
          alt="pinterest"
        />
      </div>
      <div className="details">
        Created by:
        <a href={cv} target="_blank" rel="noreferrer">
          Bethany Hodd
        </a>
        <br />
        Last Updated: 29/03/22
      </div>
    </footer>
  );
};
export default Footer;
