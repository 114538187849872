export const skills = {
  skills: [
    {
      skill: "Angular",
      level: 90,
    },
    {
      skill: "Assembly Language",
      level: 20,
    },
    {
      skill: "Bootstrap",
      level: 80,
    },
    {
      skill: "CSS",
      level: 90,
    },
    {
      skill: "Flask",
      level: 70,
    },
    {
      skill: "Git",
      level: 80,
    },
    {
      skill: "HTML",
      level: 90,
    },
    {
      skill: "Java",
      level: 80,
    },
    {
      skill: "JavaScript",
      level: 70,
    },
    {
      skill: "JQuery",
      level: 30,
    },
    {
      skill: "MySQL",
      level: 90,
    },
    {
      skill: "Node.js",
      level: 20,
    },
    {
      skill: "PHP",
      level: 60,
    },
    {
      skill: "PL/SQL",
      level: 20,
    },
    {
      skill: "Python",
      level: 90,
    },
    {
      skill: "React",
      level: 70,
    },
    {
      skill: "React Native",
      level: 30,
    },
    {
      skill: "TypeScript",
      level: 40,
    },
    {
      skill: "SpringBoot (Java)",
      level: 50,
    },
    {
      skill: "Visual Basic",
      level: 20,
    },
  ],
};
