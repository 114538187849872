import React from "react";
import Intro from "./Intro";
const Header = () => {
  return (
    <div className="header">
      <img
        className="headerImage"
        src="https://images.pexels.com/photos/6697294/pexels-photo-6697294.jpeg?cs=srgb&dl=pexels-tim-samuel-6697294.jpg&fm=jpg"
        alt="person-typing-on-keyboard-of-laptop"
      />
      <Intro />
    </div>
  );
};

export default Header;
