import React from "react";
import { projects } from "../data/projects";
import Project from "./Project";

const Projects = () => {
  return (
    <div>
      <br />
      <h2>Projects</h2>
      <table className="projectTable">
        {projects.projects.map((project) => {
          return (
            <tr className="projectRow">
              <Project project={project} />
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default Projects;
